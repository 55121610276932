import {
  AllInbox,
  Apps,
  CallSplit,
  DashboardCustomize,
  Checklist,
  DeveloperBoard,
  DevicesOther,
  Edit,
  HowToVote,
  ImageSearch,
  InsertChartOutlined,
  MenuOpen,
  MoveToInbox,
  Store,
  SupervisedUserCircle,
  TabUnselected,
  TouchApp,
  VideoLabel,
  ViewCarousel,
  WbIridescent,
  Webhook,
  MultipleStopOutlined,
} from '@mui/icons-material';
// import Assignment from '@mui/icons-material/Assignment';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import Group from '@mui/icons-material/Group';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import { AreaManagement } from './AreaManagement/AreaManagement';
import { AreaUsers } from './AreaManagement/AreaUsers';
import { BlueprintManagement } from './BlueprintManagement/BlueprintManagement';
import { Permissions } from './Common/Stores/ApiStore';
import { CompartmentDetails } from './CompartmentManagement/CompartmentDetails';
import { CompartmentManagement } from './CompartmentManagement/CompartmentManagement';
import { CompartmentSelectorManagement } from './CompartmentSelectorManagement/CompartmentSelectorManagement';
import { DevicePoolManagement } from './DevicePoolManagement/DevicePoolManagement';
import { FinderManagement } from './FinderManagement/FinderManagement';
import { LabelManagement } from './LabelManagement/LabelManagement';
import { LinkProfileManagement } from './LinkProfileManagement/LinkProfileManagement';
import { OperationGroupDetails } from './OperationGroups/OperationGroupDetails';
import { OperationGroups } from './OperationGroups/OperationGroups';
import { PickerProfileManagement } from './PickerProfileManagement/PickerProfileManagement';
import { PickingAreas } from './Picking/PickingAreas';
import { PickingSides } from './Picking/PickingSides';
import { PresetManagement } from './PresetManagement/PresetManagement';
import { ReplenishmentPlanManagement } from './ReplenishmentPlan/ReplenishmentPlanManagement';
import { RoleManagement } from './RoleManagement/RoleManagement';
import { SystemStatus } from './SystemStatus/SystemStatus';
import { TemplateManagement } from './TemplateManagement/TemplateManagement';
import { UpdaterProfileManagement } from './UpdaterProfileManagement/UpdaterProfileManagement';
// import { TaskManagement } from './TaskManagement/TaskManagement';
import { UserManagement } from './UserManagement/UserManagement';
import { UserMappings } from './UserManagement/UserMappings';
import { YourProfile } from './UserManagement/YourProfile';
import { NoPermissions } from './Welcome/Components/NoPermissions';
import { ZoneManagement } from './ZoneManagement/ZoneManagement';
import { SupplyProfileManagement } from './SupplyProfiles/SupplyProfileManagement';
import { ReceivingAreaManagement } from './ReceivingArea/ReceivingAreaManagement';
import { EventRuleManagement } from './EventRuleManagement/EventRuleManagement';
import { IntegratedServicesManagement } from './DeviceIntegrationManagement/IntegratedServicesManagement';
// import { Dashboard } from './Welcome/Components/Dashboard';

export type TitleChangeFn = (customText: string) => void;

interface TitleChangeable {
  title: string;
  change: TitleChangeFn;
}

type TitleFn = () => TitleChangeable;

export interface MenuEntry {
  link: string;
  text: string;
  icon: React.ComponentType<SvgIconProps>;
  subMenu: SubMenu;
}

export type TitleProps = { title: string };

export interface RouteShortDefinition {
  path: string;
  menu?: MenuEntry;
  component: (props: Record<string, unknown> & TitleProps) => React.JSX.Element;
  title: string;
  changeableTitle?: TitleFn;
  exact: boolean;
  permission?: string;
  children?: RouteShortDefinition[];
}

export enum SubMenu {
  LABELING = 'Shelf Labeling',
  PICKING = 'Picking',
  UNIT_CONFIG = 'Utility Components',
  TESTING = 'Danger Zone',
  SYSTEM = 'System Configuration',
  PICKINGV2 = 'Picking V2',
  EKANBAN = 'e-Kanban',
}

export const Routes: RouteShortDefinition[] = [
  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   title: 'Dashboard',
  //   exact: true,
  //   icon: DashboardIcon,
  // },
  {
    path: '/compartments/',
    menu: {
      link: '/compartments',
      text: 'Compartments',
      icon: AllInbox,
      subMenu: SubMenu.LABELING,
    },
    component: CompartmentManagement,
    title: 'Compartments',
    exact: false,
    permission: Permissions.SHELFLABELING_READ,
  },
  {
    path: '/compartments/:compartmentidentifier',
    component: CompartmentDetails,
    title: 'Compartment Details',
    exact: true,
    permission: Permissions.SHELFLABELING_READ,
  },
  {
    path: '/templates/',
    menu: {
      link: '/templates',
      text: 'Templates',
      icon: TabUnselected,
      subMenu: SubMenu.LABELING,
    },
    component: TemplateManagement,
    title: 'Templates',
    exact: false,
    permission: Permissions.TEMPLATES_READ,
  },
  {
    path: '/presets/',
    menu: {
      link: '/presets',
      text: 'Presets',
      icon: MenuOpen,
      subMenu: SubMenu.LABELING,
    },
    component: PresetManagement,
    title: 'Presets',
    exact: false,
    permission: Permissions.PRESETS_WRITE,
  },
  {
    path: '/labels/',
    menu: {
      link: '/labels',
      text: 'Labels',
      icon: VideoLabel,
      subMenu: SubMenu.LABELING,
    },
    component: LabelManagement,
    title: 'Labels',
    exact: false,
    permission: Permissions.SHELFLABELING_READ,
  },
  {
    path: '/system/',
    menu: {
      link: '/system',
      text: 'Appliances',
      icon: InsertChartOutlined,
      subMenu: SubMenu.LABELING,
    },
    component: SystemStatus,
    title: 'Appliances',
    exact: false,
    permission: Permissions.SYSTEMSTATUS_READ,
  },
  {
    path: '/integration/services/',
    menu: {
      link: '/integration/services',
      text: 'Integrated Services',
      icon: MultipleStopOutlined,
      subMenu: SubMenu.SYSTEM,
    },
    component: IntegratedServicesManagement,
    title: 'Integrated Services',
    exact: false,
    permission: Permissions.SYSTEMSTATUS_READ,
  },
  {
    path: '/picking/sides/',
    menu: {
      link: '/picking/sides',
      text: 'Sorted Sides',
      icon: HowToVote,
      subMenu: SubMenu.PICKING,
    },
    component: PickingSides,
    title: 'Picking: Sides',
    exact: false,
    permission: Permissions.PICKING_AREAS_READ,
  },
  {
    path: '/picking/areas/',
    menu: {
      link: '/picking/areas',
      text: 'Picking Areas',
      icon: Store,
      subMenu: SubMenu.PICKING,
    },
    component: PickingAreas,
    title: 'Picking: Areas',
    exact: false,
    permission: Permissions.PICKING_AREAS_READ,
  },
  {
    path: '/areas/:nodeId/users/',
    component: AreaUsers,
    title: 'Users of Area',
    exact: true,
    permission: Permissions.AREAS_READ,
  },
  {
    path: '/compartmentselectors',
    menu: {
      link: '/compartmentselectors',
      text: 'Compartment Selectors',
      icon: CallSplit,
      subMenu: SubMenu.UNIT_CONFIG,
    },
    component: CompartmentSelectorManagement,
    title: 'Compartment Selectors',
    exact: false,
    permission: Permissions.COMPARTMENT_SELECTORS_READ,
  },
  {
    path: '/linkprofiles/',
    menu: {
      link: '/linkprofiles',
      text: 'Link Profiles',
      icon: ViewCarousel,
      subMenu: SubMenu.UNIT_CONFIG,
    },
    component: LinkProfileManagement,
    title: 'Link Profiles',
    exact: false,
    permission: Permissions.LINK_PROFILES_WRITE,
  },
  {
    path: '/finders/',
    menu: {
      link: '/finders',
      text: 'Finders',
      icon: ImageSearch,
      subMenu: SubMenu.UNIT_CONFIG,
    },
    component: FinderManagement,
    title: 'Finders',
    exact: false,
    permission: Permissions.FINDERS_READ,
  },
  {
    path: '/operationgroups/',
    menu: {
      link: '/operationgroups',
      text: 'Operation-Groups',
      icon: DeveloperBoard,
      subMenu: SubMenu.UNIT_CONFIG,
    },
    component: OperationGroups,
    title: 'Operations-Groups',
    exact: false,
    permission: Permissions.OPERATION_GROUPS_READ,
  },
  {
    path: '/updaterprofiles/',
    menu: {
      link: '/updaterprofiles',
      text: 'Updater Profiles',
      icon: Edit,
      subMenu: SubMenu.UNIT_CONFIG,
    },
    component: UpdaterProfileManagement,
    title: 'Updater Profiles',
    exact: false,
    permission: Permissions.UPDATER_PROFILE_WRITE,
  },
  {
    path: '/picking/devicepools',
    menu: {
      link: '/picking/devicepools',
      text: 'Device Pools',
      icon: DevicesOther,
      subMenu: SubMenu.PICKINGV2,
    },
    component: DevicePoolManagement,
    title: 'Device Pools',
    exact: false,
    permission: Permissions.DEVICE_POOLS_READ,
  },
  {
    path: '/picking/zones/:zoneName/:coordinate',
    component: BlueprintManagement,
    title: 'Blueprints',
    exact: true,
    permission: Permissions.BLUEPRINTS_READ,
  },
  {
    path: '/picking/zones',
    menu: {
      link: '/picking/zones',
      text: 'Zones',
      icon: WbIridescent,
      subMenu: SubMenu.PICKINGV2,
    },
    component: ZoneManagement,
    title: 'Zones',
    exact: false,
    permission: Permissions.ZONES_READ,
  },
  {
    path: '/pickerprofiles/',
    menu: {
      link: '/pickerprofiles',
      text: 'Picker Profiles',
      icon: TouchApp,
      subMenu: SubMenu.PICKINGV2,
    },
    component: PickerProfileManagement,
    title: 'Picker Profiles',
    exact: false,
    permission: Permissions.PICKER_PROFILES_WRITE,
  },
  {
    path: '/areas/:id?',
    menu: {
      link: '/areas',
      text: 'Areas',
      icon: Apps,
      subMenu: SubMenu.SYSTEM,
    },
    component: AreaManagement,
    title: 'Areas',
    exact: false,
    permission: Permissions.AREAS_READ,
  },
  {
    path: '/operationgroups/:operationgroupid',
    component: OperationGroupDetails,
    title: 'Operation-Group',
    exact: true,
    permission: Permissions.OPERATION_GROUPS_READ,
  },
  {
    path: '/roles/',
    menu: {
      link: '/roles',
      text: 'Roles',
      icon: SupervisedUserCircle,
      subMenu: SubMenu.SYSTEM,
    },
    component: RoleManagement,
    title: 'Roles',
    exact: true,
    permission: Permissions.ROLES_READ,
  },
  // {
  //   path: '/tasks/',
  //   menuLink: '/tasks',
  //   component: TaskManagement,
  //   title: () => 'Tasks',
  //   exact: false,
  //   icon: Assignment,
  // },
  {
    path: '/users/:id?',
    menu: {
      link: '/users',
      text: 'Users',
      icon: Group,
      subMenu: SubMenu.SYSTEM,
    },
    component: UserManagement,
    title: 'Users',
    exact: false,
    permission: Permissions.USERS_READ,
  },
  {
    path: '/eventrules/',
    menu: {
      link: '/eventrules',
      text: 'Event Rules',
      icon: Webhook,
      subMenu: SubMenu.SYSTEM,
    },
    component: EventRuleManagement,
    title: 'Event Rules',
    exact: false,
    permission: Permissions.DEVICE_POOLS_READ,
  },
  {
    path: '/mappings/:entityType/:entityId',
    component: UserMappings,
    title: 'User-Roles',
    exact: false,
    permission: Permissions.MAPPINGS_READ,
  },
  {
    path: '/me/',
    component: YourProfile,
    exact: false,
    title: 'YourProfile',
  },
  {
    path: '/',
    component: NoPermissions,
    title: 'No Permissions',
    exact: true,
  },
  {
    path: '/ekanban/replenishment-plans',
    menu: {
      link: '/ekanban/replenishment-plans',
      text: 'Replenishment Plans',
      icon: Checklist,
      subMenu: SubMenu.EKANBAN,
    },
    component: ReplenishmentPlanManagement,
    title: 'Replenishment Plans',
    exact: false,
    permission: Permissions.EKANBAN_READ,
  },
  {
    path: '/ekanban/receiving-areas',
    menu: {
      link: '/ekanban/receiving-areas',
      text: 'Receiving Areas',
      icon: MoveToInbox,
      subMenu: SubMenu.EKANBAN,
    },
    component: ReceivingAreaManagement,
    title: 'Receiving Areas',
    exact: false,
    permission: Permissions.EKANBAN_READ,
  },
  {
    path: '/ekanban/supply-profiles',
    menu: {
      link: '/ekanban/supply-profiles',
      text: 'Supply Profiles',
      icon: DashboardCustomize,
      subMenu: SubMenu.EKANBAN,
    },
    component: SupplyProfileManagement,
    title: 'Supply Profiles',
    exact: false,
    permission: Permissions.EKANBAN_READ,
  },
];
