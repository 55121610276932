import { Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react';

interface StyleFormHeaderProps {
  label: string;
  tooltip?: string;
}

export class StyledFormHeader extends Component<StyleFormHeaderProps> {
  public render(): React.JSX.Element {
    const { label, tooltip } = this.props;

    const content = (
      <Typography
        variant="overline"
        color="primary"
        style={{ display: 'inline-block', fontWeight: 700, textAlign: 'center', width: '100%' }}
      >
        {label}
      </Typography>
    );

    if (tooltip != undefined) {
      return (
        <div>
          <Tooltip title={tooltip}>{content}</Tooltip>
        </div>
      );
    } else {
      return content;
    }
  }
}
