import {
  ClearingUpdateStrategy,
  EditMode,
  EslManagerPrivateRoute,
  HttpMethod,
  ManualUpdateStrategy,
  Preset,
  UpdateStrategyType,
} from '@ekkogmbh/apisdk';
import { RegexScanUpdateStrategy } from '@ekkogmbh/apisdk/src/Sdk/ApiTypes';
import { Checkbox, Fade, FormControlLabel, Grid, ListItemText, MenuItem, SelectChangeEvent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { PresetStore } from 'src/PresetManagement/Stores/PresetStore';
import { StyledTextField } from '../../Common/Components/Forms/StyledTextField';
import { CompartmentGeneratorForm } from '../../CompartmentManagement/Components/CompartmentGeneratorForm';
import { CompartmentGeneratorStore } from '../../CompartmentManagement/Stores/CompartmentGeneratorStore';
import { PresetPanelSorting } from '../../PresetManagement/Components/PresetPanelSorting';
import { UpdaterProfileStore } from '../Stores/UpdaterProfileStore';
import { resolveStrategyName } from '../UpdaterProfileManagement';

const styles = FormStyles;

interface UpdaterProfileConfigFormState {
  presets: Preset[];
}

interface UpdaterProfileConfigFormProps extends WithStyles<typeof styles> {
  errorCallback: () => void;
}

interface UpdaterProfileConfigFormStores {
  api: ApiStore;
  compartmentGeneratorStore: CompartmentGeneratorStore;
  updaterProfileStore: UpdaterProfileStore;
  presetStore: PresetStore;
}

type UpdaterProfileConfigFormPropsWithStores = UpdaterProfileConfigFormProps & UpdaterProfileConfigFormStores;

@inject('api', 'compartmentGeneratorStore', 'updaterProfileStore', 'presetStore')
@observer
class UpdaterProfileConfigFormComponent extends Component<
  UpdaterProfileConfigFormProps,
  UpdaterProfileConfigFormState
> {
  private fetchPromises: CancelableFetchPromises = {};

  public state: UpdaterProfileConfigFormState = {
    presets: [],
  };

  get stores(): UpdaterProfileConfigFormPropsWithStores {
    return this.props as UpdaterProfileConfigFormPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { compartmentGeneratorStore, updaterProfileStore, presetStore } = this.stores;
    const { state } = updaterProfileStore;

    const presets = await this.fetchPresets();
    this.setState({ presets });

    if (presets.length < 1) {
      enqueueSnackbar('No Presets found.', { variant: 'warning' });
    }

    if (!!state.updateStrategy) {
      const strategy = state.updateStrategy;

      switch (strategy.type) {
        case 'manual':
        case 'clearing':
          const updateStrategy = strategy as ManualUpdateStrategy | ClearingUpdateStrategy;

          if (!!state.preset && updateStrategy.params.preset === '') {
            updaterProfileStore.setState({
              updateStrategy: { ...updateStrategy, params: { ...updateStrategy.params, preset: state.preset.name } },
            });
          } else if (!state.preset && updateStrategy.params.preset !== '') {
            const presetCandidates = presets.filter((p: Preset) => p.name == updateStrategy.params.preset);
            if (presetCandidates.length > 0) {
              updaterProfileStore.setState({ preset: presetCandidates[0] });
            }
          }

          presetStore.selectedKeys = updateStrategy.params.disabledFields;
          break;
        case 'regex-scan':
          compartmentGeneratorStore.fields = (strategy as RegexScanUpdateStrategy).params.fields;
          break;
      }
    }
  }

  public async componentDidUpdate(): Promise<void> {
    const { updaterProfileStore } = this.stores;
    if (!!updaterProfileStore.state.updateStrategy) {
      switch (updaterProfileStore.state.updateStrategy.type) {
        case 'manual':
          break;
        case 'regex-scan':
          break;
        case 'clearing':
          break;
      }
    }
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private onPresetSelect = (event: SelectChangeEvent<unknown>) => {
    const { updaterProfileStore, presetStore } = this.stores;
    const { presets } = this.state;

    const value = event.target.value;
    const preset = presets[typeof value === 'number' && !isNaN(value) ? value : 0];

    // assuming strategy is defined, since you need to select it before preset selection is rendered
    const updateStrategy = updaterProfileStore.state.updateStrategy as ManualUpdateStrategy | ClearingUpdateStrategy;
    updateStrategy.params.disabledFields = this.takeOverDisabledFields(preset, updateStrategy.params.disabledFields);
    presetStore.selectedKeys = updateStrategy.params.disabledFields ?? [];
    updateStrategy.params.preset = preset.name;

    updaterProfileStore.setState({ updateStrategy, preset: preset });
  };

  /**
   * take over disabled fields in case all of them exist in the new preset keys
   */
  private takeOverDisabledFields = (newPreset: Preset, disabledFields: string[]): string[] => {
    if (
      newPreset.keys == null ||
      disabledFields.length < 1 ||
      newPreset.keys.length < Math.max(1, disabledFields.length)
    ) {
      return [];
    }

    for (let i = 0; i < disabledFields.length; i++) {
      if (newPreset.keys.indexOf(disabledFields[i]) === -1) {
        return [];
      }
    }

    return disabledFields;
  };

  private onSetDisabledFields = (disabledFields: string[]) => {
    const { updaterProfileStore } = this.stores;
    const { updateStrategy } = updaterProfileStore.state;

    // Assuming the update strategy is manual
    // selecting a preset always sets default params
    const { type, params } = updateStrategy as ManualUpdateStrategy | ClearingUpdateStrategy;

    const updatedStrategy = {
      type,
      params: {
        ...params,
        disabledFields,
      },
    } as ManualUpdateStrategy | ClearingUpdateStrategy;

    updaterProfileStore.setState({ updateStrategy: updatedStrategy });
  };

  private onEditModeSelect = (event: SelectChangeEvent<unknown>) => {
    const { updaterProfileStore } = this.stores;
    const { updateStrategy } = updaterProfileStore.state;

    // Assuming the update strategy is manual
    // selecting a preset always sets default params
    const { type, params } = updateStrategy as ManualUpdateStrategy;

    const value = event.target.value as EditMode;

    if (params !== undefined) {
      const updatedStrategy: ManualUpdateStrategy = {
        type,
        params: {
          ...params,
          editMode: value,
        },
      };

      updaterProfileStore.setState({ updateStrategy: updatedStrategy });
    }
  };

  private async fetchPresets(): Promise<Preset[]> {
    const { api, updaterProfileStore } = this.stores;
    const { coordinate } = updaterProfileStore.state;

    return request<Preset[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getAvailablePresets(coordinate),
      EslManagerPrivateRoute.AVAILABLE_PRESETS,
      HttpMethod.GET,
    );
  }

  private getPresetIndex = (preset?: Preset) => {
    const { presets } = this.state;

    if (preset === undefined) {
      return -1;
    }

    return presets.findIndex((p: Preset) => p.name === preset.name);
  };

  private renderStrategy(): React.JSX.Element {
    const { presets } = this.state;
    const { updaterProfileStore } = this.stores;
    const { updateStrategy, preset, preserveFields } = updaterProfileStore.state;
    const presetIndex = this.getPresetIndex(preset);

    const preserveFieldsElement = (
      <Tooltip title="Prevent all missing compartment fields from being dismissed when running Updater.">
        <FormControlLabel
          label="Preserve Fields"
          control={
            <Checkbox
              checked={preserveFields}
              onChange={(event) => {
                updaterProfileStore.setState({ preserveFields: event.target.checked });
              }}
            />
          }
          style={{ margin: 12 }}
        />
      </Tooltip>
    );

    switch (updaterProfileStore.state.updateStrategy?.type) {
      case UpdateStrategyType.MANUAL:
        return (
          <>
            <Grid item xs={6}>
              <StyledSelectField
                label="Preset"
                value={presetIndex !== -1 ? presetIndex : ''}
                onChange={this.onPresetSelect}
              >
                {presets.map((preset: Preset, index: number) => {
                  const presetKey = `${preset.name}`;
                  return (
                    <MenuItem key={presetKey} value={index}>
                      <ListItemText primary={presetKey} />
                    </MenuItem>
                  );
                })}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              <StyledSelectField
                label="Mode"
                value={updateStrategy!.params!.editMode ?? EditMode.BULK}
                onChange={this.onEditModeSelect}
              >
                {Object.values(EditMode).map((mode: EditMode, index: number) => {
                  const modeKey = `${index}-${mode}`;
                  return (
                    <MenuItem key={modeKey} value={mode}>
                      <ListItemText primary={mode} />
                    </MenuItem>
                  );
                })}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              {preserveFieldsElement}
            </Grid>
            {!!preset && !!preset.keys && (
              <Grid item xs={6}>
                <PresetPanelSorting
                  title="Editable Fields"
                  loading={false}
                  sortableItems={preset.keys}
                  readOnly
                  onSelectItem={this.onSetDisabledFields}
                  selectedItems={(updateStrategy as ManualUpdateStrategy).params.disabledFields ?? []}
                />
              </Grid>
            )}
          </>
        );
      case UpdateStrategyType.REGEX_SCAN:
        return (
          <>
            <Grid item xs={6}>
              {preserveFieldsElement}
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                type="text"
                label="Regex Text"
                tooltip="All compartment fields that are not disabled will be replaced by this text."
                value={updaterProfileStore.state.regexText}
                onChange={(e) => updaterProfileStore.setState({ regexText: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <CompartmentGeneratorForm noCoordinate />
            </Grid>
          </>
        );
      case UpdateStrategyType.CLEARING:
        return (
          <>
            <Grid item xs={6}>
              <StyledSelectField
                label="Preset"
                value={presetIndex !== -1 ? presetIndex : ''}
                onChange={this.onPresetSelect}
              >
                {presets.map((preset: Preset, index: number) => {
                  const presetKey = `${preset.name}`;
                  return (
                    <MenuItem key={presetKey} value={index}>
                      <ListItemText primary={presetKey} />
                    </MenuItem>
                  );
                })}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                type="text"
                label="Clearing Text"
                tooltip="All compartment fields that are not disabled will be replaced by this text."
                value={updaterProfileStore.state.clearingText}
                onChange={(e) => updaterProfileStore.setState({ clearingText: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              {preserveFieldsElement}
            </Grid>
            {!!preset && !!preset.keys && (
              <Grid item xs={6}>
                <PresetPanelSorting
                  title="Fields to clear"
                  loading={false}
                  sortableItems={preset.keys}
                  readOnly
                  onSelectItem={this.onSetDisabledFields}
                  selectedItems={(updateStrategy as ManualUpdateStrategy).params.disabledFields ?? []}
                />
              </Grid>
            )}
          </>
        );
      case undefined:
        return <></>;
    }
  }

  private handleStrategySelection(event: SelectChangeEvent<unknown>, updaterProfileStore: UpdaterProfileStore): void {
    const previousStrategy = updaterProfileStore.state.updateStrategy;
    if (!!previousStrategy) {
      if ((previousStrategy.type as string) === event.target.value) {
        return;
      }
    }

    const previousPresetState =
      previousStrategy?.type === 'manual' || previousStrategy?.type === 'clearing'
        ? {
            preset: (previousStrategy as ManualUpdateStrategy | ClearingUpdateStrategy).params.preset,
            disabledFields:
              (previousStrategy as ManualUpdateStrategy | ClearingUpdateStrategy).params.disabledFields ?? [],
          }
        : { preset: '', disabledFields: [] };

    switch (event.target.value as UpdateStrategyType) {
      case 'manual':
        updaterProfileStore.setState({
          updateStrategy: {
            type: 'manual',
            params: { ...previousPresetState, editMode: EditMode.BULK },
          } as ManualUpdateStrategy,
        });
        break;
      case 'regex-scan':
        updaterProfileStore.setState({
          updateStrategy: { type: 'regex-scan', params: { fields: [] } } as RegexScanUpdateStrategy,
        });
        break;
      case 'clearing':
        updaterProfileStore.setState({
          updateStrategy: {
            type: 'clearing',
            params: { ...previousPresetState, clearingText: '' },
          } as ClearingUpdateStrategy,
        });
    }
  }

  public render(): React.JSX.Element {
    const { updaterProfileStore } = this.stores;
    const currentStrategy = updaterProfileStore.state.updateStrategy;

    return (
      <Fade in={true} timeout={1000}>
        <Grid container alignItems="stretch">
          <Grid container item xs={12} spacing={1} alignItems="stretch" alignContent="stretch">
            <Grid item xs={6}>
              <StyledSelectField
                label="Type"
                value={currentStrategy?.type ?? ''}
                onChange={(e) => this.handleStrategySelection(e, updaterProfileStore)}
              >
                {Object.values(UpdateStrategyType).map((type: UpdateStrategyType, index: number) => (
                  <MenuItem key={index} value={type}>
                    <ListItemText primary={resolveStrategyName(type)} />
                  </MenuItem>
                ))}
              </StyledSelectField>
            </Grid>
            {this.renderStrategy()}
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

const StyleWrapped = withStyles(styles)(UpdaterProfileConfigFormComponent);

export const UpdaterProfileConfigForm = StyleWrapped;
