import { StartupStrategyType, Zone } from '@ekkogmbh/apisdk';
import { Fade, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { PickerProfileStore } from '../Stores/PickerProfileStore';
import { ZonePicker } from 'src/ZoneManagement/ZonePicker';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';

const styles = FormStyles;

interface PickerProfileConfigurationFormState {}

interface PickerProfileConfigurationFormProps extends WithStyles<typeof styles> {
  errorCallback: () => void;
}

interface PickerProfileConfigurationFormStores {
  api: ApiStore;
  pickerProfileStore: PickerProfileStore;
  notificationStrategyStore: NotificationStrategyStore;
}

type PickerProfileConfigurationFormPropsWithStores = PickerProfileConfigurationFormProps &
  PickerProfileConfigurationFormStores;

@inject('api', 'pickerProfileStore', 'notificationStrategyStore')
@observer
class PickerProfileConfigurationFormComponent extends Component<
  PickerProfileConfigurationFormProps,
  PickerProfileConfigurationFormState
> {
  public state: PickerProfileConfigurationFormState = {};

  get stores(): PickerProfileConfigurationFormPropsWithStores {
    return this.props as PickerProfileConfigurationFormPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { pickerProfileStore, notificationStrategyStore } = this.stores;
    const { startupStrategy } = pickerProfileStore.state;

    // initialize state for pre–selected SelectFields
    if (startupStrategy === undefined) {
      pickerProfileStore.setState({ startupStrategy: { name: StartupStrategyType.SELECT, config: {} } });
    }

    if (!notificationStrategyStore.changed) {
      const { editablePickerProfile } = pickerProfileStore;
      const initialNotificationStrategy =
        editablePickerProfile?.notificationStrategy ?? editablePickerProfile?.zone.notificationStrategy;
      notificationStrategyStore.resetStore(initialNotificationStrategy);
    }
  }

  public render(): React.JSX.Element {
    const { pickerProfileStore } = this.stores;
    const { zone, name } = pickerProfileStore.state;

    const isDisabled = pickerProfileStore.editablePickerProfile !== undefined;

    return (
      <Fade in={true} timeout={1000}>
        <Grid container justifyContent={'center'} alignItems={'flex-start'} spacing={2}>
          <Grid container item xs={6} spacing={1} alignContent={'stretch'}>
            <Grid item xs={12}>
              <StyledFormHeader label={'Picker Profile'} />
              <StyledTextField
                disabled={isDisabled}
                type="text"
                label="Name"
                value={name}
                onChange={(e) => pickerProfileStore.setState({ name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <ZonePicker
                disabled={isDisabled}
                selected={zone}
                onChange={(zone: Zone | undefined) => pickerProfileStore.setState({ zone })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

const StyleWrapped = withStyles(styles)(PickerProfileConfigurationFormComponent);

export const PickerProfileConfigurationForm = StyleWrapped;
