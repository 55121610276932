import {
  Action,
  ActionName,
  CombinedTrigger,
  EventTrigger,
  EventType,
  LinearStateMachineDefinition,
  ReplenishmentPlan,
} from '@ekkogmbh/apisdk';
import { Add, Delete, Edit, Settings } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Stack, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { StyledFormHeader } from '../../Common/Components/Forms/StyledFormHeader';
import { copy } from '../../Common/Helper/FormHelper';
import { ReplenishmentPlanStore } from '../Stores/ReplenishmentPlanStore';
import { EventTriggerForm } from 'src/EventRuleManagement/Components/EventTriggerForm';
import { EventTriggerStore } from 'src/EventRuleManagement/Stores/EventTriggerStore';

const styles = FormStyles;

interface ReplenishmentPlanPanelStores {
  api: ApiStore;
  replenishmentPlanStore: ReplenishmentPlanStore;
  eventTriggerStore: EventTriggerStore;
}

interface ReplenishmentPlanPanelState {
  loading: boolean;
  form: {
    index: number | null;
    state: string;
    allFilled: boolean;
  };
}

export interface ReplenishmentPlanPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (replenishmentPlan: ReplenishmentPlan) => Promise<ReplenishmentPlan>;
}

const defaultFormState = {
  index: null,
  state: '',
  trigger: {
    type: EventType.DEVICE_TRIGGER,
    properties: { deviceTriggerIndex: '1' },
  },
  action: {
    name: ActionName.NOOP,
  },
  allFilled: false,
};

@inject('api', 'replenishmentPlanStore', 'eventTriggerStore')
@observer
class ReplenishmentPlanPanelComponent extends React.Component<
  ReplenishmentPlanPanelProps,
  ReplenishmentPlanPanelState
> {
  public state: ReplenishmentPlanPanelState = {
    loading: false,
    form: copy(defaultFormState),
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): ReplenishmentPlanPanelStores {
    return this.props as ReplenishmentPlanPanelProps & ReplenishmentPlanPanelStores;
  }

  public componentDidMount(): void {
    const { replenishmentPlanStore } = this.stores;
    const { editableReplenishmentPlan } = replenishmentPlanStore;

    if (editableReplenishmentPlan !== undefined) {
      replenishmentPlanStore.setEditableReplenishmentPlan(editableReplenishmentPlan);

      this.setState({
        form: copy(defaultFormState),
      });
    }
  }

  public componentWillUnmount(): void {
    const { replenishmentPlanStore, eventTriggerStore } = this.stores;
    replenishmentPlanStore.resetStore();
    eventTriggerStore.reset();
    cancelFetchPromises(this.fetchPromises);
  }

  public handleReset = async () => {
    const { replenishmentPlanStore } = this.stores;
    const { editableReplenishmentPlan } = replenishmentPlanStore;

    if (editableReplenishmentPlan !== undefined) {
      replenishmentPlanStore.setEditableReplenishmentPlan(editableReplenishmentPlan);

      this.setState({
        form: copy(defaultFormState),
      });
    } else {
      this.setState(
        {
          form: copy(defaultFormState),
        },
        this.componentWillUnmount,
      );
    }
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;
    const { replenishmentPlanStore } = this.stores;

    const { name, coordinate, linearStateMachineDefinition } = replenishmentPlanStore.state;

    const payload = {
      name,
      coordinate,
      linearStateMachineDefinition,
    };

    this.setState({ loading: true }, async () => {
      await saveHandler(payload);
      this.handleReset();
      closeHandler();
    });
  };

  public onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { form } = this.state;
    const state = e.target.value;
    const allFilled = state !== '';

    this.setState({
      form: {
        ...form,
        state,
        allFilled,
      },
    });
  };

  public onSaveState = () => {
    const { replenishmentPlanStore, eventTriggerStore } = this.stores;
    const { form } = this.state;
    const { linearStateMachineDefinition } = replenishmentPlanStore.state;

    if (!form.allFilled) {
      return; // error message, toast, whatever?
    }

    if (form.index === null && !linearStateMachineDefinition.states.includes(form.state)) {
      linearStateMachineDefinition.states.push(form.state);
    } else if (form.index === null && linearStateMachineDefinition.states.includes(form.state)) {
      return; // dont save since state already exists and user wasnt editing this state? maybe show error?
    } else if (form.index !== null) {
      if (form.state !== linearStateMachineDefinition.states[form.index]) {
        const oldStateName = linearStateMachineDefinition.states[form.index];
        delete linearStateMachineDefinition.triggers[oldStateName];
        delete linearStateMachineDefinition.onEnterActions[oldStateName];
      }

      linearStateMachineDefinition.states[form.index] = form.state;
    }

    linearStateMachineDefinition.triggers[form.state] = eventTriggerStore.getConsolidatedTrigger();

    linearStateMachineDefinition.onEnterActions[form.state] = { name: ActionName.NOOP };
    replenishmentPlanStore.setState({ linearStateMachineDefinition });
    eventTriggerStore.reset();

    this.setState({
      form: copy(defaultFormState),
    });
  };

  public onClickEditState = (index: number) => () => {
    const { replenishmentPlanStore, eventTriggerStore } = this.stores;
    const { linearStateMachineDefinition } = replenishmentPlanStore.state;

    const state = linearStateMachineDefinition.states[index];
    eventTriggerStore.reset(linearStateMachineDefinition.triggers[state]);

    this.setState({
      form: {
        index,
        state,
        allFilled: true,
      },
    });
  };

  public onClickDeleteState = (index: number) => () => {
    const { replenishmentPlanStore } = this.stores;
    const { linearStateMachineDefinition } = replenishmentPlanStore.state;

    const state = linearStateMachineDefinition.states[index];

    linearStateMachineDefinition.states.splice(index, 1);

    delete linearStateMachineDefinition.triggers[state];
    delete linearStateMachineDefinition.onEnterActions[state];

    replenishmentPlanStore.setState({ linearStateMachineDefinition });
  };

  public renderState = (index: number, state: string, trigger: EventTrigger, onEnterAction: Action) => {
    const { onClickEditState, onClickDeleteState } = this;

    return (
      <Box key={'lsmd-state-' + index} p={1}>
        <Card variant={'outlined'}>
          <CardHeader
            title={
              <Typography variant="h5" component="h2">
                {state}
              </Typography>
            }
            action={
              <>
                <IconButton aria-label="edit" onClick={onClickEditState(index)}>
                  <Settings />
                </IconButton>
                <IconButton aria-label="delete" onClick={onClickDeleteState(index)}>
                  <Delete />
                </IconButton>
              </>
            }
          />
          <CardContent>
            <Typography color="textSecondary" variant={'overline'}>
              Trigger
            </Typography>
            <Typography fontWeight={700}>{trigger.type}</Typography>
            <Box pl={4}>
              {Object.keys(trigger.properties).map((key) => {
                if (trigger.type === 'combined') {
                  const combinedNames = (trigger as CombinedTrigger).properties.triggers.map(
                    (t: EventTrigger) => t.type,
                  );
                  return (
                    <Typography key={key} color={'textSecondary'}>
                      {combinedNames.join(', ')}
                    </Typography>
                  );
                }
                return (
                  <Typography key={key} color="textSecondary">
                    {key}: {trigger.properties[key]}
                  </Typography>
                );
              })}
            </Box>
            <Typography color="textSecondary" variant={'overline'}>
              Action
            </Typography>
            <Typography fontWeight={700}>{onEnterAction.name}</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  };

  public renderStates = (linearStateMachineDefinition: LinearStateMachineDefinition) => (
    <Stack spacing={2}>
      {linearStateMachineDefinition.states.map((state, index) =>
        this.renderState(
          index,
          state,
          linearStateMachineDefinition.triggers[state],
          linearStateMachineDefinition.onEnterActions[state],
        ),
      )}
    </Stack>
  );

  public renderFormState = () => {
    const { form } = this.state;
    const { replenishmentPlanStore } = this.stores;
    const { coordinate, linearStateMachineDefinition } = replenishmentPlanStore.state;
    const { onChangeState } = this;

    const trigger = linearStateMachineDefinition.triggers[form.state] ?? undefined;

    return (
      <Stack spacing={2}>
        <StyledTextField type="text" label="Name" value={form.state} onChange={onChangeState} autoFocus={true} />
        <div style={{ paddingLeft: 12 }}>
          <Typography color="textSecondary" variant={'overline'}>
            Triggers
          </Typography>
          <EventTriggerForm
            initialTrigger={trigger}
            coordinate={coordinate}
            excludeTypes={[
              EventType.PICKING_ORDER_STARTED,
              EventType.PICKING_ORDER_CONCLUDED,
              EventType.EKANBAN_STATE_TRANSITION,
            ]}
          />
        </div>
      </Stack>
    );
  };

  public render() {
    const { closeHandler } = this.props;
    const { loading, form } = this.state;
    const { replenishmentPlanStore, eventTriggerStore } = this.stores;
    const { coordinate, name, linearStateMachineDefinition, allFilled } = replenishmentPlanStore.state;
    const { editableReplenishmentPlan } = replenishmentPlanStore;

    const isEditState = form.index !== null;
    const formStateExists = linearStateMachineDefinition.states.includes(form.state);
    const stateButtonDisabled = !eventTriggerStore.isSavable() || !form.allFilled || (formStateExists && !isEditState);

    const coordinateSelectDisabled =
      editableReplenishmentPlan !== undefined || eventTriggerStore.hasTriggerOfType(EventType.COMPARTMENT_FOUND);

    const { onSaveState, renderStates, renderFormState } = this;

    const states = renderStates(linearStateMachineDefinition);

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12} container spacing={2} alignItems={'stretch'} style={{ display: loading ? 'block' : 'none' }}>
          <Grid
            item
            xs={12}
            style={{
              height: 496,
              position: 'relative',
            }}
          >
            <div
              style={{
                top: '50%',
                marginTop: -48,
                position: 'absolute',
                width: '100%',
              }}
            >
              <CheckmarkSpinner complete={false} failure={false} />
            </div>
          </Grid>
        </Grid>

        {!loading && (
          <Grid item container mt={0} spacing={1} alignContent={'stretch'}>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <StyledFormHeader label={'Identifier'} />
                <StyledTextField
                  type="text"
                  label="Name"
                  value={name}
                  disabled={editableReplenishmentPlan !== undefined}
                  onChange={(e) => replenishmentPlanStore.setState({ name: e.target.value })}
                />
                <CoordinateInput
                  value={coordinate}
                  disabled={coordinateSelectDisabled}
                  onChange={(coordinate) => replenishmentPlanStore.setState({ coordinate })}
                  trailingDelimiter={false}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <StyledFormHeader label={(isEditState ? 'Edit' : 'Add') + ' State'} />
                {renderFormState()}
                <Grid container mt={0} spacing={0} alignContent={'stretch'} alignItems={'end'} alignSelf={'stretch'}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={onSaveState}
                      disabled={stateButtonDisabled}
                      style={{ margin: 8 }}
                    >
                      {isEditState ? <Edit /> : <Add />} {isEditState ? 'Edit' : 'Add'} State
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <StyledFormHeader label={'States'} />
                {linearStateMachineDefinition.states.length > 0 && states}
              </Stack>
            </Grid>
          </Grid>
        )}
        <FormPanelButtons
          cancelHandler={closeHandler}
          saveHandler={this.handleSave}
          resetHandler={this.handleReset}
          isDeleteHidden={true}
          isSaveDisabled={!allFilled}
          finished={loading}
        />
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(ReplenishmentPlanPanelComponent);

export const ReplenishmentPlanPanel = StyleWrapped;
