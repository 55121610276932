import { BlinkColor, NotificationStrategy } from '@ekkogmbh/apisdk';
import { Grid, IconButton, ListItemText, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { BlinkColorPicker } from 'src/Common/Components/Forms/BlinkColorPicker';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { NotificationStrategyStore } from '../Stores/NotificationStrategyStore';
import { inject, observer } from 'mobx-react';
import { StyledCheckbox } from 'src/Common/Components/Forms/StyledCheckbox';
import { Add, ArrowLeft, ArrowRight, Remove } from '@mui/icons-material';

const styles = FormStyles;

interface NotificationStrategyFormStores {
  notificationStrategyStore: NotificationStrategyStore;
}

interface NotificationStrategyFormState {
  currentStrategy: number;
}

interface NotificationStrategyFormProps extends WithStyles<typeof styles> {}

@inject('notificationStrategyStore')
@observer
class NotificationStrategyFormComponent extends React.Component<
  NotificationStrategyFormProps,
  NotificationStrategyFormState
> {
  public state: NotificationStrategyFormState = {
    currentStrategy: 0,
  };

  get stores(): NotificationStrategyFormStores {
    return this.props as NotificationStrategyFormProps & NotificationStrategyFormStores;
  }

  public componentDidUpdate(
    _prevProps: Readonly<NotificationStrategyFormProps>,
    _prevState: Readonly<NotificationStrategyFormState>,
    _snapshot?: unknown,
  ): void {
    const { currentStrategy } = this.state;
    const { notificationStrategyStore } = this.stores;
    const { strategies } = notificationStrategyStore;

    // resets may cause strategies to disappear. ensure the pointer is valid.
    if (currentStrategy > 0 && currentStrategy + 1 > strategies.length) {
      this.setState({ currentStrategy: 0 });
    }
  }

  private renderListControls = (): JSX.Element => {
    const { currentStrategy } = this.state;
    const { notificationStrategyStore } = this.stores;
    const { strategies } = notificationStrategyStore;

    return (
      <Stack
        direction={'row'}
        spacing={1}
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'baseline',
        }}
      >
        <IconButton
          disabled={currentStrategy == 0 || strategies.length == 0}
          onClick={() => this.setState({ currentStrategy: currentStrategy - 1 })}
        >
          <ArrowLeft fontSize={'inherit'} />
        </IconButton>
        <Typography>
          {strategies.length > 0 ? (currentStrategy + 1).toString() + '/' + strategies.length.toString() : ''}
        </Typography>
        <IconButton
          disabled={currentStrategy + 1 === strategies.length}
          onClick={() => this.setState({ currentStrategy: currentStrategy + 1 })}
        >
          <ArrowRight fontSize={'inherit'} />
        </IconButton>
        <IconButton
          onClick={() => {
            notificationStrategyStore.addStrategy();
            this.setState({ currentStrategy: strategies.length });
          }}
        >
          <Tooltip title={'add new strategy'}>
            <Add />
          </Tooltip>
        </IconButton>
        <IconButton
          disabled={strategies.length == 0}
          onClick={() => {
            this.setState({ currentStrategy: currentStrategy > 0 ? currentStrategy - 1 : 0 }, () =>
              notificationStrategyStore.removeStrategy(currentStrategy),
            );
          }}
        >
          <Tooltip title={'remove selected strategy'}>
            <Remove />
          </Tooltip>
        </IconButton>
      </Stack>
    );
  };

  private renderConfigForm = () => {
    const { currentStrategy } = this.state;
    const { notificationStrategyStore } = this.stores;
    const { name, configuration } = notificationStrategyStore.strategies[currentStrategy];

    switch (name) {
      case NotificationStrategy.LIGHT:
        const { duration, color, redirectTarget, redirectDepth, bypassQueue, disablePrevious } = configuration;

        return (
          <Grid container spacing={0} alignContent={'stretch'}>
            <Grid item xs={6}>
              <StyledCheckbox
                label={'Bypass Queue'}
                value={Boolean(bypassQueue)}
                onChange={(_e) =>
                  notificationStrategyStore.setStrategy(currentStrategy, {
                    name,
                    configuration: {
                      ...configuration,
                      bypassQueue: !bypassQueue,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <StyledCheckbox
                label={'Disable Previous Result'}
                tooltip={'stops active blinking of previously found devices'}
                value={Boolean(disablePrevious)}
                onChange={(_e) =>
                  notificationStrategyStore.setStrategy(currentStrategy, {
                    name,
                    configuration: {
                      ...configuration,
                      disablePrevious: !disablePrevious,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                type={'number'}
                label={'Duration (seconds)'}
                value={String(duration) ?? '0'}
                onChange={(e) =>
                  notificationStrategyStore.setStrategy(currentStrategy, {
                    name,
                    configuration: {
                      ...configuration,
                      duration: parseInt(e.target.value) < 0 ? '0' : String(e.target.value),
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <BlinkColorPicker
                value={color ?? BlinkColor.RED}
                onChange={(e) =>
                  notificationStrategyStore.setStrategy(currentStrategy, {
                    name,
                    configuration: {
                      ...configuration,
                      color: e.target.value as BlinkColor,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSelectField
                value={redirectTarget ?? 'null'}
                label={'Redirect Blink Request To'}
                onChange={(e) => {
                  if (e.target.value === 'null') {
                    const changedConfig = configuration;
                    delete changedConfig.redirectTarget;
                    delete changedConfig.redirectDepth;

                    notificationStrategyStore.setStrategy(currentStrategy, {
                      name,
                      configuration: changedConfig,
                    });
                  } else {
                    notificationStrategyStore.setStrategy(currentStrategy, {
                      name,
                      configuration: {
                        ...configuration,
                        redirectTarget: e.target.value as string,
                        redirectDepth: '1',
                      },
                    });
                  }
                }}
              >
                <MenuItem value={'null'}>
                  <ListItemText primary={'-'} />
                </MenuItem>
                <MenuItem value={'ancestor'}>
                  <ListItemText primary={'Parent Node'} />
                </MenuItem>
                <MenuItem value={'common-ancestor'}>
                  <ListItemText primary={'Largest Common Coordinate Prefix'} />
                </MenuItem>
              </StyledSelectField>
            </Grid>
            {redirectTarget && (
              <Grid item xs={12}>
                <StyledTextField
                  type={'number'}
                  label={'Redirect Depth'}
                  value={String(redirectDepth)}
                  onChange={(e) =>
                    notificationStrategyStore.setStrategy(currentStrategy, {
                      name,
                      configuration: {
                        ...configuration,
                        redirectDepth: parseInt(e.target.value) <= 0 ? '1' : String(e.target.value),
                      },
                    })
                  }
                />
              </Grid>
            )}
          </Grid>
        );
      case NotificationStrategy.MQTT:
        const { topic } = configuration;

        return (
          <Grid container spacing={0} alignContent={'stretch'}>
            <Grid item xs={12}>
              <StyledTextField
                type={'text'}
                label={'Topic'}
                value={topic ? (topic as string) : 'ekko/notification'}
                onChange={(e) =>
                  notificationStrategyStore.setStrategy(currentStrategy, {
                    name,
                    configuration: {
                      ...configuration,
                      topic: String(e.target.value),
                    },
                  })
                }
              />
            </Grid>
          </Grid>
        );
      default:
        return <></>;
    }
  };

  public render() {
    const { currentStrategy } = this.state;
    const { notificationStrategyStore } = this.stores;
    const { strategies } = notificationStrategyStore;

    return (
      <Grid container spacing={2} alignContent={'stretch'}>
        {this.renderListControls()}
        {strategies.length > 0 && strategies[currentStrategy] && (
          <>
            <Grid item xs={12}>
              <StyledSelectField
                value={strategies[currentStrategy].name}
                label={'Notification Strategy'}
                onChange={(e) =>
                  notificationStrategyStore.setStrategy(currentStrategy, {
                    configuration: strategies[currentStrategy].configuration,
                    name: e.target.value as NotificationStrategy,
                  })
                }
              >
                <MenuItem value={NotificationStrategy.LIGHT}>
                  <ListItemText primary={NotificationStrategy.LIGHT} />
                </MenuItem>
                <MenuItem value={NotificationStrategy.MQTT}>
                  <ListItemText primary={NotificationStrategy.MQTT} />
                </MenuItem>
              </StyledSelectField>
            </Grid>
            <Grid item xs={12}>
              {this.renderConfigForm()}
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(NotificationStrategyFormComponent);

export const NotificationStrategyForm = StyleWrapped;
