import {
  ConfirmationPattern,
  NotificationStrategy,
  Side,
  ValueExtender,
  ZonePayload,
  ZoneStrategy,
} from '@ekkogmbh/apisdk';
import { Settings } from '@mui/icons-material';
import { Divider, Grid, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { ObjectListForm } from 'src/Common/Components/Forms/ObjectListForm';
import { StyledCheckbox } from 'src/Common/Components/Forms/StyledCheckbox';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { DynamicStepper, DynamicStepperStep } from 'src/Common/Components/Stepper/DynamicStepper';
import { noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { EventTriggerForm } from 'src/EventRuleManagement/Components/EventTriggerForm';
import { EventTriggerStore } from 'src/EventRuleManagement/Stores/EventTriggerStore';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { spacer } from '../../Common/Components/Forms/Spacer';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { StyledTextField } from '../../Common/Components/Forms/StyledTextField';
import { invalidMergeConfig, MergeStrategies } from '../Helper/Strategies';
import { NotificationStrategyStore } from '../Stores/NotificationStrategyStore';
import { ZoneStore } from '../Stores/ZoneStore';
import { DevicePoolPicker } from './DevicePoolPicker';
import { NotificationStrategyForm } from './NotificationStrategyForm';
import { StrategyPicker } from './StrategyPicker';

const styles = FormStyles;

const stores = ['api', 'zoneStore', 'notificationStrategyStore', 'configStore', 'eventTriggerStore'];

interface ZonePanelStores {
  api: ApiStore;
  zoneStore: ZoneStore;
  configStore: ConfigStore;
  notificationStrategyStore: NotificationStrategyStore;
  eventTriggerStore: EventTriggerStore;
}

interface ZonePanelState {
  loading: boolean;
  activeStep: number;
  strategyForm: {
    open: boolean;
    type: string;
    strategy: ZoneStrategy | null;
  };
}

export interface ZonePanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (savableZone: ZonePayload, allowOverwrite: boolean) => Promise<void>;
}

export type ZonePanelPropsWithStores = ZonePanelProps & ZonePanelStores;

@inject(...stores)
@observer
class ZonePanelComponent extends React.Component<ZonePanelProps, ZonePanelState> {
  public state: ZonePanelState = {
    loading: false,
    activeStep: 0,
    strategyForm: {
      open: false,
      type: '',
      strategy: null,
    },
  };

  get stores(): ZonePanelStores {
    return this.props as ZonePanelPropsWithStores;
  }

  public componentWillUnmount(): void {
    const { zoneStore, notificationStrategyStore, eventTriggerStore } = this.stores;
    eventTriggerStore.reset();
    notificationStrategyStore.resetStore();
    zoneStore.resetStore();
  }

  private namingStep = (): DynamicStepperStep => {
    const { zoneStore, configStore } = this.stores;
    const {
      name,
      coordinate,
      sortSteps,
      maxParallelOpenOrders,
      autoConclude,
      autoDelete,
      buttonConfirmationEventTopic,
      confirmationTrigger,
    } = zoneStore.state;

    const sortStepsOptions = [
      <MenuItem key={`sort-steps-option-on`} value={'no'}>
        <ListItemText primary={'NO'} />
      </MenuItem>,
      <MenuItem key={`sort-steps-option-bysource`} value={'by-source'}>
        <ListItemText primary={'BY-SOURCE'} />
      </MenuItem>,
      <MenuItem key={`sort-steps-option-bydestination`} value={'by-destination'}>
        <ListItemText primary={'BY-DESTINATION'} />
      </MenuItem>,
    ];

    const buttonConfirmationEventTopicOptions = configStore.config.buttonConfirmEventTopics.map((topic: string) => (
      <MenuItem key={topic} value={topic}>
        <ListItemText primary={topic} />
      </MenuItem>
    ));
    buttonConfirmationEventTopicOptions.unshift(
      <MenuItem key={'disable'} value={''}>
        <ListItemText primary={'disable'} />
      </MenuItem>,
    );

    return {
      title: 'Zone',
      elementCallback: () => (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={'column'} spacing={1} alignItems={'stretch'} alignContent={'stretch'}>
              <StyledFormHeader label={'Identifier'} />
              <StyledTextField
                type={'text'}
                label={'Name'}
                value={name}
                disabled={zoneStore.editableZone !== undefined}
                onChange={(e) => zoneStore.setState({ name: e.target.value })}
              />
              <CoordinateInput
                value={coordinate}
                disabled={zoneStore.editableZone !== undefined}
                onChange={(coordinate) => zoneStore.setState({ coordinate })}
                trailingDelimiter={false}
              />
              <StyledFormHeader label={'Confirmation Trigger'} />
              <div>
                <StyledSelectField
                  value={buttonConfirmationEventTopic ?? ''}
                  label={'Confirmation Event Topic'}
                  onChange={(e) => {
                    zoneStore.setState({
                      buttonConfirmationEventTopic: e.target.value as string,
                    });
                  }}
                >
                  {buttonConfirmationEventTopicOptions}
                </StyledSelectField>
              </div>
              <EventTriggerForm coordinate={coordinate} initialTrigger={confirmationTrigger} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'column'} alignItems={'stretch'}>
              <StyledFormHeader label={'Order Configuration'} />
              <StyledSelectField
                value={sortSteps ?? 'no'}
                label={'Sort Steps'}
                onChange={(e) => {
                  zoneStore.setState({
                    sortSteps: e.target.value as string,
                  });
                }}
              >
                {sortStepsOptions}
              </StyledSelectField>
              <StyledTextField
                type={'number'}
                label={'Max Parallel Open Orders'}
                value={maxParallelOpenOrders ?? 0}
                onChange={(e) =>
                  zoneStore.setState({
                    maxParallelOpenOrders: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value),
                  })
                }
              />
              <StyledCheckbox
                label={'Automatically Conclude Orders'}
                value={!!autoConclude}
                onChange={(e) => {
                  zoneStore.setState({
                    autoConclude: (e.target as HTMLInputElement).checked,
                  });
                }}
                tooltip={'Aborting an order or completing all setps flags the order as concluded'}
              />
              <StyledCheckbox
                label={'Automatically Delete Concluded Orders'}
                value={!!autoDelete}
                onChange={(e) => {
                  zoneStore.setState({
                    autoDelete: (e.target as HTMLInputElement).checked,
                  });
                }}
                tooltip={'Delete orders when they are flagged as concluded'}
              />
            </Stack>
          </Grid>
        </Grid>
      ),
    };
  };

  private sidesStep = (): DynamicStepperStep => {
    const { zoneStore } = this.stores;
    const {
      coordinate,
      sourcePoolIdentifier,
      destinationPoolIdentifier,
      sourceValueExtenders,
      destinationValueExtenders,
      sourceConfirmationPattern,
      sourceOptionalConfirmation,
      destinationConfirmationPattern,
      destinationOptionalConfirmation,
    } = zoneStore.state;

    const confirmationPatternOptions = Object.values(ConfirmationPattern).map((pattern: string) => (
      <MenuItem key={`confirmation-mode-option-${pattern}`} value={pattern}>
        <ListItemText primary={pattern.toUpperCase().replace('_', '-')} />
      </MenuItem>
    ));

    return {
      title: 'Sides Configuration',
      elementCallback: () => (
        <Grid container spacing={2} alignContent={'stretch'}>
          <Grid container item xs={6} spacing={1} alignContent={'flex-start'}>
            <Grid item xs={12}>
              <StyledFormHeader label={'Source'} />
            </Grid>
            <Grid item xs={12}>
              <DevicePoolPicker
                label={'Device Pool'}
                optional
                coordinate={coordinate}
                selected={sourcePoolIdentifier}
                onChange={(newSelected) => zoneStore.setState({ sourcePoolIdentifier: newSelected })}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledSelectField
                value={sourceConfirmationPattern ?? ConfirmationPattern.DEFAULT}
                label={'Confirmation Pattern'}
                onChange={(e) => {
                  zoneStore.setState({
                    sourceConfirmationPattern: e.target.value as ConfirmationPattern,
                  });
                }}
              >
                {confirmationPatternOptions}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              <StyledCheckbox
                label={'Optional Confirmations'}
                value={!!sourceOptionalConfirmation}
                onChange={() =>
                  zoneStore.setState({
                    sourceOptionalConfirmation: !sourceOptionalConfirmation,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ObjectListForm
                title={'Source Value Extenders'}
                items={sourceValueExtenders ?? []}
                onChange={(items: ValueExtender[]) => zoneStore.setState({ sourceValueExtenders: items })}
                defaultItem={
                  {
                    annotation: '',
                    pattern: '/^([a-Z]*)$/',
                    replacement: '$1_$value',
                    description: 'insert parent node value',
                  } as ValueExtender
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={6} spacing={1} alignContent={'flex-start'}>
            <Grid item xs={12}>
              <StyledFormHeader label={'Destination'} />
            </Grid>
            <Grid item xs={12}>
              <DevicePoolPicker
                label={'DevicePool'}
                optional
                coordinate={coordinate}
                selected={destinationPoolIdentifier}
                onChange={(newSelected) => zoneStore.setState({ destinationPoolIdentifier: newSelected })}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledSelectField
                value={destinationConfirmationPattern ?? ConfirmationPattern.DEFAULT}
                label={'Confirmation Pattern'}
                onChange={(e) => {
                  zoneStore.setState({
                    destinationConfirmationPattern: e.target.value as ConfirmationPattern,
                  });
                }}
              >
                {confirmationPatternOptions}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              <StyledCheckbox
                label={'Optional Confirmations'}
                value={!!destinationOptionalConfirmation}
                onChange={() =>
                  zoneStore.setState({
                    destinationOptionalConfirmation: !destinationOptionalConfirmation,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ObjectListForm
                title={'Destination Value Extenders'}
                items={destinationValueExtenders ?? []}
                onChange={(items: ValueExtender[]) => zoneStore.setState({ destinationValueExtenders: items })}
                defaultItem={
                  {
                    annotation: '',
                    pattern: '/^([a-Z]*)$/',
                    replacement: '$1_$value',
                    description: 'insert parent node value',
                  } as ValueExtender
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ),
    };
  };

  private strategyConfigForm = (type: string, strategy: ZoneStrategy): React.ReactNode | null => {
    switch (type) {
      case 'confirmation':
        return this.confirmationStrategyConfigForm(strategy);
      case 'notification':
        return (
          <Grid container spacing={0} alignContent={'flex-start'}>
            <Grid item xs={12}>
              <StyledFormHeader label={'Notification Strategies'} />
            </Grid>
            <Grid item xs={12}>
              <NotificationStrategyForm />
            </Grid>
          </Grid>
        );
      case 'merge':
        return this.mergeStrategyConfigForm(strategy);
      default:
        return null;
    }
  };

  private confirmationStrategyConfigForm = (strategy: ZoneStrategy): React.ReactNode | null => {
    const { zoneStore } = this.stores;
    switch (strategy.name) {
      case 'default':
        return (
          <Grid container spacing={0} alignContent={'flex-start'}>
            <Grid item xs={12}>
              <StyledFormHeader label={'Default Confirmation Strategy'} />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                type={'number'}
                label={'Active Value Limit'}
                value={String(zoneStore.state.confirmationStrategy.configuration.activeValueLimit) ?? '3'}
                onChange={(e) =>
                  zoneStore.setState({
                    confirmationStrategy: {
                      name: zoneStore.state.confirmationStrategy.name,
                      configuration: {
                        activeValueLimit: parseInt(e.target.value) < 1 ? '1' : String(e.target.value),
                      },
                    },
                  })
                }
              />
            </Grid>
          </Grid>
        );
      case 'random':
        return (
          <Grid container spacing={0} alignContent={'flex-start'}>
            <Grid item xs={12}>
              <StyledFormHeader label={'Random Confirmation Strategy'} />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                type={'number'}
                label={'Confirmation Count Completes Step'}
                tooltip={'setting to 0 disables the confirmation limit'}
                value={String(zoneStore.state.confirmationStrategy.configuration.confirmationCountCompletesStep) ?? '0'}
                onChange={(e) =>
                  zoneStore.setState({
                    confirmationStrategy: {
                      name: zoneStore.state.confirmationStrategy.name,
                      configuration: {
                        confirmationCountCompletesStep: parseInt(e.target.value) < 0 ? '0' : String(e.target.value),
                      },
                    },
                  })
                }
              />
            </Grid>
          </Grid>
        );
      case 'strict':
      case 'NULL':
      default:
        return null;
    }
  };

  private mergeStrategyConfigForm = (strategy: ZoneStrategy): React.ReactNode | null => {
    const { zoneStore } = this.stores;

    switch (strategy.name) {
      case 'distribute':
      case 'multiplex': {
        return (
          <StyledSelectField
            label={MergeStrategies[strategy.name].label}
            value={(zoneStore.state.mergeStrategy.configuration[MergeStrategies[strategy.name].field] as string) ?? ''}
            onChange={(e) =>
              zoneStore.setState({
                mergeStrategy: {
                  ...zoneStore.state.mergeStrategy,
                  configuration: {
                    ...zoneStore.state.mergeStrategy.configuration,
                    [MergeStrategies[strategy.name].field]: e.target.value as string,
                  },
                },
              })
            }
          >
            {Object.values(Side).map((side) => (
              <MenuItem key={side} value={side}>
                <ListItemText primary={side} />
              </MenuItem>
            ))}
          </StyledSelectField>
        );
      }
      default:
        return null;
    }
  };

  private strategiesStep = (): DynamicStepperStep => {
    const { zoneStore, notificationStrategyStore } = this.stores;
    const { confirmationStrategy, mergeStrategy } = zoneStore.state;
    const { strategyForm } = this.state;

    const confirmationStrategies: ZoneStrategy[] = [
      {
        name: 'default',
        configuration: {
          activeValueLimit: '3',
        },
      },
      {
        name: 'strict',
        configuration: {},
      },
      {
        name: 'random',
        configuration: {
          confirmationCountCompletesStep: '0',
        },
      },
    ];

    const mergeStrategies: ZoneStrategy[] = [
      {
        name: 'append',
        configuration: {},
      },
      ...Object.keys(MergeStrategies).map((strategyName) => ({
        name: strategyName,
        configuration: {
          [MergeStrategies[strategyName].field]: '',
        },
      })),
    ];

    const strategyHasConfiguration = (strategy: ZoneStrategy): boolean =>
      Object.keys(strategy.configuration).length > 0;

    const strategyClickHandler = (type: string, strategy: ZoneStrategy) => (): void => {
      // opens or closes the corresponding form via state.strategyForm
      if (
        strategyForm.type === type &&
        strategyForm.strategy !== null &&
        strategyForm.strategy.name === strategy.name
      ) {
        this.setState({
          strategyForm: {
            type,
            strategy,
            open: !strategyForm.open,
          },
        });
      } else {
        this.setState({
          strategyForm: {
            type,
            strategy,
            open: true,
          },
        });
      }
    };

    return {
      title: 'Strategies',
      elementCallback: () => (
        <Grid container spacing={0} alignContent={'stretch'}>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={2} alignContent={'stretch'}>
              <Grid item xs={12}>
                <Grid container spacing={0} alignContent={'stretch'}>
                  <Grid item xs={11}>
                    <StrategyPicker
                      label={'Confirmation Strategy'}
                      strategies={confirmationStrategies}
                      selected={confirmationStrategy.name}
                      onChange={(newSelected) => {
                        this.setState({
                          strategyForm: {
                            type: 'confirmation',
                            strategy: newSelected,
                            open: false,
                          },
                        });
                        zoneStore.setState({ confirmationStrategy: newSelected });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ display: 'inline-flex' }}>
                    <Button
                      style={{ margin: 8, marginLeft: 10, flexGrow: 1 }}
                      variant="outlined"
                      color="secondary"
                      disabled={!strategyHasConfiguration(confirmationStrategy)}
                      onClick={strategyClickHandler('confirmation', confirmationStrategy)}
                    >
                      <Settings fontSize={'medium'} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0} alignContent={'stretch'}>
                  <Grid item xs={11}>
                    <StrategyPicker
                      label={'Merge Strategy'}
                      strategies={mergeStrategies}
                      selected={mergeStrategy.name}
                      onChange={(newSelected) => {
                        this.setState({
                          strategyForm: {
                            type: 'merge',
                            strategy: newSelected,
                            open: Object.keys(MergeStrategies).includes(newSelected.name),
                          },
                        });
                        zoneStore.setState({ mergeStrategy: newSelected });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ display: 'inline-flex' }}>
                    <Button
                      style={{ margin: 8, marginLeft: 10, flexGrow: 1 }}
                      variant="outlined"
                      color="secondary"
                      disabled={!strategyHasConfiguration(mergeStrategy)}
                      onClick={strategyClickHandler('merge', mergeStrategy)}
                    >
                      <Settings fontSize={'medium'} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0} alignContent={'stretch'}>
                  <Grid item xs={11}>
                    <StyledSelectField value={0} label={'Notification Strategy'} disabled={true} onChange={() => noop}>
                      <MenuItem value={0}>
                        <ListItemText primary={notificationStrategyStore.getMetaStrategy()} />
                      </MenuItem>
                    </StyledSelectField>
                  </Grid>
                  <Grid item xs={1} style={{ display: 'inline-flex' }}>
                    <Button
                      style={{ margin: 8, marginLeft: 10, flexGrow: 1 }}
                      variant="outlined"
                      color="secondary"
                      onClick={strategyClickHandler('notification', {
                        name: NotificationStrategy.NULL,
                        configuration: {},
                      })}
                    >
                      <Settings fontSize={'medium'} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid
              container
              spacing={2}
              alignContent={'stretch'}
              style={{ marginLeft: 4, marginRight: 4, width: '100%' }}
            >
              <Grid item xs={12}>
                {strategyForm.open &&
                  strategyForm.strategy !== null &&
                  this.strategyConfigForm(strategyForm.type, strategyForm.strategy)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    };
  };

  private overviewStep = (): DynamicStepperStep => {
    const { zoneStore } = this.stores;
    const {
      coordinate,
      name,
      maxParallelOpenOrders,
      sortSteps,
      sourcePoolIdentifier,
      destinationPoolIdentifier,
      sourceConfirmationPattern,
      destinationConfirmationPattern,
      confirmationStrategy,
      mergeStrategy,
      autoConclude,
      autoDelete,
      sourceOptionalConfirmation,
      destinationOptionalConfirmation,
    } = zoneStore.state;

    const { notificationStrategyStore } = this.stores;

    return {
      title: 'Overview',
      elementCallback: (): React.JSX.Element => (
        <Grid container spacing={0} alignContent={'stretch'} style={{ wordBreak: 'break-word' }}>
          <Grid item lg={6} xs={12} style={{ padding: 8 }}>
            <Grid container spacing={2} alignContent={'stretch'}>
              <Grid item xs={12}>
                <Typography variant={'overline'} color={'secondary'}>
                  Identifier
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h5'} color={'secondary'}>
                  Name
                </Typography>
                <Typography variant={'h4'}>{name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h5'} color={'secondary'}>
                  Coordinate
                </Typography>
                <Typography variant={'h4'}>{coordinate}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'overline'} color={'secondary'}>
                  Options
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'subtitle1'} color={'secondary'}>
                  Max Parallel Open Orders
                </Typography>
                <Typography variant={'subtitle2'}>{maxParallelOpenOrders}</Typography>
              </Grid>
              {spacer(6)}
              <Grid item xs={6}>
                <Typography variant={'subtitle1'} color={'secondary'}>
                  Sort Steps
                </Typography>
                <Typography variant={'subtitle2'}>{sortSteps}</Typography>
              </Grid>
              {spacer(6)}
              <Grid item xs={6}>
                <Typography variant={'subtitle1'} color={'secondary'}>
                  Automatically conclude orders
                </Typography>
                <Typography variant={'subtitle2'}>{autoConclude ? 'yes' : 'no'}</Typography>
              </Grid>
              {spacer(6)}
              <Grid item xs={6}>
                <Typography variant={'subtitle1'} color={'secondary'}>
                  Automatically delete concluded orders
                </Typography>
                <Typography variant={'subtitle2'}>{autoDelete ? 'yes' : 'no'}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12} style={{ padding: 8 }}>
            <Grid container spacing={2} alignContent={'stretch'}>
              <Grid item xs={12}>
                <Typography variant={'overline'} color={'secondary'}>
                  Device Pools
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h6'} color={'secondary'}>
                  Source Pool
                </Typography>
                <Typography variant={'h5'}>
                  {sourcePoolIdentifier ? `${sourcePoolIdentifier.name} - ${sourcePoolIdentifier.coordinate}` : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h6'} color={'secondary'}>
                  Destination Pool
                </Typography>
                <Typography variant={'h5'}>
                  {destinationPoolIdentifier
                    ? `${destinationPoolIdentifier.name} - ${destinationPoolIdentifier.coordinate}`
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h6'} color={'secondary'}>
                  Confirmation Pattern
                </Typography>
                <Typography variant={'h5'}>{sourceConfirmationPattern}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h6'} color={'secondary'}>
                  Confirmation Pattern
                </Typography>
                <Typography variant={'h5'}>{destinationConfirmationPattern}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h6'} color={'secondary'}>
                  Optional Confirmations
                </Typography>
                <Typography variant={'h5'}>{sourceOptionalConfirmation ? 'true' : 'false'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h6'} color={'secondary'}>
                  Optional Confirmations
                </Typography>
                <Typography variant={'h5'}>{destinationOptionalConfirmation ? 'true' : 'false'}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ padding: 8 }}>
            <Grid container spacing={2} alignContent={'stretch'}>
              <Grid item xs={12}>
                <Typography variant={'overline'} color={'secondary'}>
                  Strategies
                </Typography>
                <Divider />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography variant={'h6'} color={'secondary'}>
                  Confirmation Strategy
                </Typography>
                <Typography variant={'h5'}>{confirmationStrategy.name}</Typography>
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography variant={'h6'} color={'secondary'}>
                  Notification Strategy
                </Typography>
                <Typography variant={'h5'}>
                  {notificationStrategyStore.getSavableNotificationStrategy().name}
                </Typography>
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography variant={'h6'} color={'secondary'}>
                  Merge Strategy
                </Typography>
                <Typography variant={'h5'}>{mergeStrategy.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    };
  };

  public handleSave = async (): Promise<void> => {
    const { saveHandler, closeHandler } = this.props;
    const { zoneStore, notificationStrategyStore, eventTriggerStore } = this.stores;

    const overwrite = zoneStore.editableZone !== undefined;
    const savableZone = zoneStore.getSavableZone();
    savableZone.notificationStrategy = notificationStrategyStore.getSavableNotificationStrategy();
    savableZone.confirmationTrigger = eventTriggerStore.getConsolidatedTrigger();

    await saveHandler(savableZone, overwrite);
    closeHandler();
  };

  public render() {
    const { closeHandler } = this.props;
    const { activeStep } = this.state;
    const { zoneStore } = this.stores;
    const { name, coordinate, mergeStrategy } = zoneStore.state;

    const steps = [this.namingStep(), this.sidesStep(), this.strategiesStep(), this.overviewStep()];

    const isNextDisabled =
      (activeStep === 0 && (name === '' || coordinate === '')) ||
      activeStep + 1 === steps.length ||
      invalidMergeConfig(mergeStrategy);

    return (
      <Grid container spacing={2} alignContent={'stretch'}>
        <Grid item xs={12}>
          <DynamicStepper activeStep={activeStep} steps={steps} />
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            nextHandler={() => this.setState({ activeStep: activeStep + 1 })}
            backHandler={() => this.setState({ activeStep: activeStep - 1 })}
            saveHandler={this.handleSave}
            isNextDisabled={isNextDisabled}
            isBackDisabled={activeStep === 0}
            isSaveDisabled={!zoneStore.isSaveable}
            cancelHandler={closeHandler}
            isDeleteHidden={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(ZonePanelComponent);

export const ZonePanel = StyleWrapped;
