import { OrderPreparationStep, PanelConfiguration, PickerProfile, StartupStrategy, Zone } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface PickerProfileState {
  name: string;
  zone?: Zone;
  startupStrategy?: StartupStrategy;
  sourcePanel?: PanelConfiguration;
  destinationPanel?: PanelConfiguration;
  orderPreparationSteps?: Array<OrderPreparationStep>;
  allFilled: boolean;
}

export class PickerProfileStore {
  @observable
  public editablePickerProfile?: PickerProfile;

  @observable
  public state: PickerProfileState = {
    name: '',
    zone: undefined,
    startupStrategy: undefined,
    sourcePanel: undefined,
    destinationPanel: undefined,
    orderPreparationSteps: undefined,
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<PickerProfileState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled =
        this.state.name !== '' &&
        this.state.zone !== undefined &&
        this.state.startupStrategy !== undefined &&
        this.state.sourcePanel !== undefined &&
        this.state.destinationPanel !== undefined;

      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetStore = (): void => {
    this.setState({
      name: '',
      zone: undefined,
      startupStrategy: undefined,
      sourcePanel: undefined,
      destinationPanel: undefined,
      orderPreparationSteps: undefined,
      allFilled: false,
    });

    this.editablePickerProfile = undefined;
  };

  @action
  public setEditablePickerProfile = (pickerProfile?: PickerProfile): void => {
    this.editablePickerProfile = pickerProfile;

    if (pickerProfile) {
      const {
        name,
        zone,
        startupStrategy,
        uiConfiguration: { sourcePanel, destinationPanel },
        orderPreparationSteps,
      } = pickerProfile;

      this.setState({
        name,
        zone,
        startupStrategy,
        sourcePanel,
        destinationPanel,
        orderPreparationSteps,
        allFilled: true,
      });
    }
  };
}
