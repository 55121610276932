import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { StyledTextField } from './Forms/StyledTextField';
import { AddCircle, InfoRounded, Loop, RemoveCircle } from '@mui/icons-material';

const styles = FormStyles;

interface AnnotationDialogState {
  newKey: string;
  changedEntries: Record<string, Record<string, string>>;
  removedEntries: Array<string>;
  loading: boolean;
  currentTab: string;
}

interface AnnotationDialogProps extends WithStyles<typeof styles>, Omit<DialogProps, 'classes' | 'title' | 'onSubmit'> {
  title: string;
  annotations: Record<string, Record<string, string>>;
  systemKeys?: Array<string>;
  editable?: boolean;
  isEditableFilter?: (key: string, entry: Record<string, string>) => boolean | string;
  isRemovableFilter?: (key: string, entry: Record<string, string>) => boolean | string;
  onSaveCallback?: (saveableAnnotations: Record<string, string>) => Promise<unknown>; // FIXME: system vs custom annotations may need to split?
  onClose: () => void;
}

class AnnotationDialogComponent extends Component<AnnotationDialogProps, AnnotationDialogState> {
  public state: AnnotationDialogState = {
    newKey: '',
    changedEntries: {},
    removedEntries: [],
    loading: false,
    currentTab: 'custom',
  };

  public getConsolidatedEntries(): Record<string, Record<string, string>> {
    const { changedEntries, removedEntries } = this.state;
    const { annotations } = this.props;

    const consolidated = { ...annotations };
    Object.keys(changedEntries).forEach((key: string) => {
      consolidated[key] = { ...changedEntries[key] };
    });
    removedEntries.forEach((key: string) => {
      delete consolidated[key];
    });

    return consolidated;
  }

  public onAddEntry(): void {
    const { newKey, changedEntries, removedEntries } = this.state;

    const removedIndex = removedEntries.indexOf(newKey);
    if (removedIndex > -1) {
      removedEntries.splice(removedIndex, 1);
      this.setState({
        newKey: '',
        removedEntries: [...removedEntries],
      });
    } else {
      this.setState({
        newKey: '',
        changedEntries: {
          ...changedEntries,
          [newKey]: { value: '' },
        },
      });
    }
  }

  public onChangeEntry(key: string, value: string): void {
    const { annotations } = this.props;
    const { changedEntries, removedEntries } = this.state;

    const entry = annotations[key];
    const newChangedEntries = { ...changedEntries, [key]: { ...entry, value: value } };

    const removedIndex = removedEntries.indexOf(key);
    if (removedIndex >= 0) {
      removedEntries.splice(removedIndex, 1);

      this.setState({
        removedEntries: [...removedEntries],
        changedEntries: newChangedEntries,
      });
    } else {
      this.setState({
        changedEntries: newChangedEntries,
      });
    }
  }

  public onResetEntry(key: string) {
    const { changedEntries, removedEntries } = this.state;

    const indexOfRemoved = removedEntries.indexOf(key);
    if (indexOfRemoved >= 0) {
      removedEntries.splice(indexOfRemoved, 1);
      this.setState({ removedEntries: [...removedEntries] });
      return;
    }

    const changedKeys = Object.keys(changedEntries);
    if (changedKeys.includes(key)) {
      delete changedEntries[key];
      this.setState({ changedEntries: { ...changedEntries } });
    }
  }

  public onRemoveEntry(key: string): void {
    const { removedEntries } = this.state;

    removedEntries.push(key);
    this.setState({ removedEntries: [...removedEntries] });
  }

  public onSave(): void {
    const { onSaveCallback, onClose } = this.props;

    if (onSaveCallback) {
      const { removedEntries, changedEntries } = this.state;

      const annotationChanges: Record<string, string> = {};
      Object.keys(changedEntries).forEach((key: string) => (annotationChanges[key] = changedEntries[key]['value']));
      removedEntries.forEach((key: string) => (annotationChanges[key] = ''));

      this.setState({ loading: true }, async () => {
        await onSaveCallback(annotationChanges);
        onClose();
      });
    } else {
      onClose();
    }
  }

  public onReset(): void {
    this.setState({
      newKey: '',
      changedEntries: {},
      removedEntries: [],
    });
  }

  private makeEntryAdornment(key: string, entry: Record<string, string>): React.JSX.Element {
    const { isRemovableFilter, editable } = this.props;
    const { changedEntries } = this.state;

    const removableResult = isRemovableFilter === undefined || isRemovableFilter(key, entry);
    const isKeyChanged = Object.keys(changedEntries).includes(key);

    if (removableResult === true && !isKeyChanged && editable) {
      return (
        <IconButton onClick={() => this.onRemoveEntry(key)} disabled={false} size="large">
          <RemoveCircle />
        </IconButton>
      );
    } else if (isKeyChanged) {
      // resetable value
      return (
        <IconButton onClick={() => this.onResetEntry(key)} size="large">
          <Loop />
        </IconButton>
      );
    } else if (typeof removableResult === 'string') {
      // not deletable and unchanged - display info
      return (
        <div style={{ paddingRight: 12, paddingTop: 4 }}>
          <Tooltip title={removableResult} placement={'left'}>
            <InfoRounded />
          </Tooltip>
        </div>
      );
    }

    return <></>;
  }

  public render(): React.JSX.Element {
    const {
      systemKeys,
      title,
      classes,
      editable,
      isEditableFilter,
      isRemovableFilter,
      onSaveCallback,
      onClose,
      ...rest
    } = this.props;
    const { newKey, currentTab } = this.state;

    if (isRemovableFilter && onSaveCallback) {
      // noop
    }
    const consolidatedAnnotations = this.getConsolidatedEntries();
    const annotationKeys = Object.keys(consolidatedAnnotations);
    const customKeys = annotationKeys.filter(
      (key: string) => !systemKeys || !systemKeys.map((key: string) => '_' + key).includes(key),
    );

    return (
      <Dialog onClose={onClose} {...rest}>
        <DialogTitle id={'Annotation-Dialog-Title'}>{title}</DialogTitle>
        <DialogContent>
          <Tabs value={currentTab} onChange={(_, newTab) => this.setState({ currentTab: newTab })}>
            <Tab label={'custom'} value={'custom'} />
            <Tab label={'system'} value={'system'} />
          </Tabs>
          {currentTab === 'custom' && (
            <List dense className={classes.root}>
              {customKeys.map((annoKey: string) => {
                const entry = consolidatedAnnotations[annoKey];
                const { value } = entry;

                const isEditableResult = isEditableFilter === undefined || isEditableFilter(annoKey, entry);
                const isEntryEditable = editable && isEditableResult === true;

                return (
                  <ListItem key={`annotation-item-${annoKey}`}>
                    <Grid container spacing={0} alignItems={'stretch'}>
                      <Grid item xs={12}>
                        <StyledTextField
                          label={annoKey}
                          type="text"
                          value={value}
                          onChange={(e) => this.onChangeEntry(annoKey, String(e.target.value))}
                          disabled={!isEntryEditable}
                          endAdornment={this.makeEntryAdornment(annoKey, entry)}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
              {editable && (
                <>
                  <Divider />
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <StyledTextField
                          label="Annotation Key"
                          type="text"
                          value={newKey}
                          onChange={(e) => this.setState({ newKey: String(e.target.value) })}
                          endAdornment={
                            <IconButton
                              onClick={() => this.onAddEntry()}
                              disabled={newKey.startsWith('_') || newKey == '' || annotationKeys.includes(newKey)}
                              size="large"
                            >
                              <AddCircle />
                            </IconButton>
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )}
            </List>
          )}
          {systemKeys && currentTab === 'system' && (
            <List dense className={classes.root}>
              {systemKeys.map((annoKey: string) => {
                const prefixedKey = '_' + annoKey;

                let entry: Record<string, string>;
                if (prefixedKey in consolidatedAnnotations) {
                  entry = consolidatedAnnotations[prefixedKey];
                } else {
                  entry = { [prefixedKey]: '' };
                }

                return (
                  <ListItem key={`annotation-item-${annoKey}`}>
                    <Grid container spacing={0} alignItems={'stretch'}>
                      <Grid item xs={12}>
                        <StyledTextField
                          label={annoKey}
                          type="text"
                          value={entry.value ?? ''}
                          onChange={(e) => this.onChangeEntry(prefixedKey, String(e.target.value))}
                          disabled={!editable}
                          endAdornment={this.makeEntryAdornment(prefixedKey, entry)}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} color={'secondary'} className={classes.button} onClick={onClose}>
            close
          </Button>
          {editable && (
            <>
              <Button
                variant={'contained'}
                color={'secondary'}
                className={classes.button}
                onClick={() => this.onReset()}
              >
                reset
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.onSave()}
              >
                save
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const StyleWrapped = withStyles(styles)(AnnotationDialogComponent);
export const AnnotationDialog = StyleWrapped;
